<template>
  <v-sheet class="pa-4">
    <v-sheet class="d-flex justify-end">
      <v-btn @click="show_archived = !show_archived" small outlined>
        <v-icon v-if="!show_archived" class="mr-3">mdi-eye-outline</v-icon>
        <v-icon v-else class="mr-3">mdi-eye-off-outline</v-icon>
        {{ !show_archived ? 'Show' : 'Hide' }} archived
      </v-btn>
      <v-btn class="ml-2" @click="adding_season = true" small outlined>
        <v-icon>mdi-plus</v-icon>
        Add season
      </v-btn>
    </v-sheet>
    <v-sheet class="d-flex justify-center my-4" v-if="loading_seasons">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-sheet>
    <league-season
      v-for="season in show_archived ? seasons : unarchived_seasons"
      :key="season.id + '_season'"
      :season="season"
    ></league-season>
    <v-dialog
      v-model="adding_season"
      max-width="800"
      min-height="800"
    >
      <v-card class="pa-8" flat min-height="800">
        <v-sheet class="text-h5 mb-4">
          Add season
        </v-sheet>
        <v-text-field
          v-model="new_season.season_name"
          label="Name"
          counter="30"
        ></v-text-field>
        <v-text-field
          v-model="new_season.season_info"
          label="Info"
          counter="300"
        ></v-text-field>
        <v-sheet>
          Season starts
        </v-sheet>
        <VueCtkDateTimePicker
          v-model="new_season.starttime"
          format="YYYY-MM-DD HH:mm"
          output-format="x"
        ></VueCtkDateTimePicker>
        <v-sheet>
          Season ends
        </v-sheet>
        <VueCtkDateTimePicker
          v-model="new_season.endtime"
          format="YYYY-MM-DD HH:mm"
          output-format="x"
        ></VueCtkDateTimePicker>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="createSeason" color="primary">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LeagueSeason from '@/components/tiimi_admin/league/season/LeagueSeason'

export default {
  components: { LeagueSeason },
  data: () => ({
    adding_season: false,
    new_season: {
      season_name: '',
      season_info: '',
      starttime: null,
      endtime: null
    },
    show_archived: false
  }),
  created() {
    this.initSeasons( this.$route.params.league_id )
  },
  methods: {
    ...mapActions('admin', [
      'initSeasons',
      'addSeason'
    ]),
    ...mapActions('noti', [
      'error',
      'success'
    ]),
    createSeason() {
      const league_id = this.$route.params.league_id
      this.addSeason({ ...this.new_season, league_id })
        .then(() => {
          this.success('season created')
        })
        .catch(e => {
          this.error(e)
        })
    }
  },
  computed: {
    ...mapGetters('admin', [
      'seasons',
      'loading_seasons',
      'unarchived_seasons'
    ])
  }
}
</script>