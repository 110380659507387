<template>
  <v-sheet class="pa-3 my-3" style="border: 1px solid lightgrey; border-radius: 5px;">
    <v-sheet class="d-flex align-center">
      <v-sheet class="text-button">
        {{ season.season_name }}
      </v-sheet>
      <v-icon v-if="season.archived" class="ml-3">mdi-archive-outline</v-icon>
      <v-chip outlined class="ml-10" label small>{{ season.starttime.substring(0, 10).split('-').reverse().join('.') }}</v-chip>
      <v-icon size="10">mdi-minus</v-icon>
      <v-chip outlined class="ml-1" label small>{{ season.endtime.substring(0, 10).split('-').reverse().join('.') }}</v-chip>
      <v-spacer></v-spacer>
      <v-chip small label text @click="updateArchived(season.id, !season.archived)">
        <v-icon v-if="!season.archived">mdi-archive-outline</v-icon>
        <v-icon v-else>mdi-archive-off-outline</v-icon>
      </v-chip>
    </v-sheet>
    <v-divider class="mt-2" />
    <v-sheet class="d-flex align-center mt-3">
      <!-- SECOND ROW FOR SEASON STATES -->
      <v-sheet class="text-button mr-3">
        Game states
      </v-sheet>
      <v-chip 
        class="ml-1" 
        v-for="season_state in season.states" 
        :key="season_state.id"
        label
        outlined
      >
        {{ season_state.state_name }}
      </v-chip>
      <v-btn
        text
        x-small
        fab
        class="ml-2"
        @click="adding_analysis_state = true"
      >
        <v-icon size="15">mdi-plus</v-icon>
      </v-btn>
    </v-sheet>
    <v-dialog
      v-model="adding_analysis_state"
      max-width="600"
    >
      <v-card>
        <v-card-title>
          Add analysis state
        </v-card-title>
        <v-card-text>
          <v-text-field
            counter="20"
            label="Name"
            v-model="new_analysis_state.state_name"
          ></v-text-field>
          <v-text-field
            counter="200"
            label="Info"
            v-model="new_analysis_state.state_text"
          ></v-text-field>
          <v-checkbox
            v-model="new_analysis_state.game_done"
            label="Last state (game analysis done after this)"
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="adding_analysis_state = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn 
            color="primary"
            @click="createAnalysisState"
          >
            Create
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: ['season'],
  data: () => ({
    adding_analysis_state: false,
    new_analysis_state: {
      state_name: '',
      state_text: '',
      game_done: false
    }
  }),
  created() {
    this.getSeasonStates({ id: this.season.id })
  },
  methods: {
    ...mapActions('admin', [
      'updateSeason',
      'getSeasonStates',
      'addSeasonState'
    ]),
    ...mapActions('noti', [
      'success',
      'error'
    ]),
    clear() {
      this.new_analysis_state = {
        state_name: '',
        state_text: '',
        game_done: false
      }
    },
    createAnalysisState() {
      this.addSeasonState({
        season_id: this.season.id,
        state: this.new_analysis_state
      })
      .then(() => {
        this.success('Season analysis state created')
        this.clear()
        this.adding_analysis_state = false
      })
      .catch(e => {
        this.error(e)
      })
    },
    updateArchived(id, val) {
      this.updateSeason({ id, archived: val })
        .then(() => {
          this.success('updated')
        })
        .catch(e => {
          this.error(e)
        })
    }
  }
}
</script>